import { createSlice } from '@reduxjs/toolkit';
import { updateCart } from '../utils/cartUtils';

const initialState = localStorage.getItem('cart')
	? JSON.parse(localStorage.getItem('cart'))
	: { cartItems: [], shippingAddress: {}, paymentMethod: '' };

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		addToCart: (state, action) => {
			const { user, ...item } = action.payload;

			const existItem = state.cartItems.find(
				(x) => x._id === item._id
			);

			if (existItem) {
				state.cartItems = state.cartItems.map((x) =>
					x._id === existItem._id ? item : x
				);
			} else {
				state.cartItems = [...state.cartItems, item];
			}
			return updateCart(state, item);
		},
		removeFromCart: (state, action) => {
			state.cartItems = state.cartItems.filter(
				(x) => x._id !== action.payload
			);
			return updateCart(state);
		},
		saveShippingAddress: (state, action) => {
			state.shippingAddress = action.payload;
			localStorage.setItem('cart', JSON.stringify(state));
		},
		savePaymentMethod: (state, action) => {
			state.paymentMethod = action.payload;
			localStorage.setItem('cart', JSON.stringify(state));
		},
		clearCartItems: (state, action) => {
			state.cartItems = [];
			// Remove prices from cart
			state.itemsPrice = 0;
			state.shippingPrice = 0;
			state.taxPrice = 0;
			state.totalPrice = 0;

			localStorage.setItem('cart', JSON.stringify(state));
		},
		resetCart: (state, action) => {
			state.cartItems = [];
			state.shippingAddress = {};
			state.paymentMethod = '';
			localStorage.removeItem('cart');
		},
	},
});

export const {
	addToCart,
	removeFromCart,
	saveShippingAddress,
	savePaymentMethod,
	clearCartItems,
	resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
